import React from "react"
import { Container } from "react-bootstrap"

import SEO from "../utils/seo";
import Layout from "../layout/layout";
import ContentContainer from "../components/ContentContainer"


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <ContentContainer heading="NOT FOUND">
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </ContentContainer>      
    </Container>
  </Layout>
);

export default NotFoundPage;
